// import axios from 'axios';
import { hanatourApi } from '../../main.js';
export default class Newloginchart {


  


    async getnewloginchart(obj) {
        var params = new URLSearchParams(obj).toString();
        return await hanatourApi.get(`admin/statistics/new-logins?${params}`)
            .then((res) => res)
            .catch((err) => err)

    }


    async getchatroomchart(obj) {
        var params = new URLSearchParams(obj).toString();
        return await hanatourApi.get(`admin/statistics/new-chatrooms?${params}`)
            .then((res) => res)
            .catch((err) => err)

    }

    




}